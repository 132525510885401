import React from 'react';
import { createTheme, darkTheme, lightTheme } from '@backstage/theme';
import { createBackstageHeaderOverrides } from './header';
import { AppTheme } from '@backstage/core-plugin-api';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

function getNavigationPalette() {
  return {
    navigation: {
      background: '#424242',
      indicator: '#ffffff',
      color: '#b5b5b5',
      selectedColor: '#FFF',
    },
  };
}

function createLightTheme(): AppTheme {
  const theme = {
    ...createTheme({
      palette: {
        ...lightTheme.palette,
        ...getNavigationPalette(),
      },
      fontFamily: 'Red Hat Display',
      defaultPageTheme: 'home',
    }),
    overrides: {
      ...lightTheme.overrides,
      ...createBackstageHeaderOverrides(),
    },
  };
  return {
    id: 'onewelcome-light',
    title: 'Light Theme',
    variant: 'light',
    Provider: ({ children }) => (
      <ThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  };
}

function createDarkTheme(): AppTheme {
  const theme = {
    ...createTheme({
      palette: {
        ...darkTheme.palette,
        ...getNavigationPalette(),
      },
      fontFamily: 'Red Hat Display',
      defaultPageTheme: 'home',
    }),
    overrides: {
      ...darkTheme.overrides,
      ...createBackstageHeaderOverrides(),
    },
  };
  return {
    id: 'onewelcome-dark',
    title: 'Dark Theme',
    variant: 'dark',
    Provider: ({ children }) => (
      <ThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  };
}

export function getThemes(): AppTheme[] {
  return [createLightTheme(), createDarkTheme()];
}
