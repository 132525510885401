import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'use', name: 'USE', color: '#93c47d' });
rings.push({ id: 'experimental', name: 'EXPERIMENTAL', color: '#93d2c2' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'architecture', name: 'Architecture' });
quadrants.push({
  id: 'process-dev-tech',
  name: 'Process & Development techniques',
});
quadrants.push({
  id: 'langs-frameworks-tools',
  name: 'Languages, Frameworks & Tools',
});
quadrants.push({ id: 'infra-platforms', name: 'Infrastructure & platforms' });

const entries = new Array<RadarEntry>();

// *******************************************************************************
// **************** Languages, Frameworks & Tools entries ************************
// *******************************************************************************
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'git',
  id: 'git',
  title: 'Git',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'java',
  id: 'java',
  title: 'Java',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'rxjava-for-mobile',
  id: 'rxjava-for-mobile',
  title: 'RxJava [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'spring',
  id: 'spring',
  title: 'Spring framework',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'react-redux',
  id: 'react-redux',
  title: 'React & Redux',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'node',
  id: 'node',
  title: 'Node',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'golang-scripts',
  id: 'golang-scripts',
  title: 'Golang for scripts',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'kotlin',
  id: 'kotlin',
  title: 'Kotlin',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'kotlin-for-mobile',
  id: 'kotlin-for-mobile',
  title: 'Kotlin [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'python-serverless',
  id: 'python-serverless',
  title: 'Python for Serverless',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'node-serverless',
  id: 'node-serverless',
  title: 'Node for Serverless',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'maven',
  id: 'maven',
  title: 'Maven',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'gradle',
  id: 'gradle',
  title: 'Gradle',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'terraform',
  id: 'terraform',
  title: 'Terraform',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'skaffold',
  id: 'skaffold',
  title: 'Skaffold',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'lombok',
  id: 'lombok',
  title: 'Lombok',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'objective-c-for-mobile',
  id: 'objective-c-for-mobile',
  title: 'Objective-C [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'swift-for-mobile',
  id: 'swift-for-mobile',
  title: 'Swift [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'flutter-for-mobile',
  id: 'flutter-for-mobile',
  title: 'Flutter [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'react-native-for-mobile',
  id: 'react-native-for-mobile',
  title: 'ReactNative [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'cordova-for-mobile',
  id: 'cordova-for-mobile',
  title: 'Cordova [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'dexguard-for-mobile',
  id: 'dexguard-for-mobile',
  title: 'DexGuard [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'secureblackbox-for-mobile',
  id: 'secureblackbox-for-mobile',
  title: 'SecureBlackBox [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'ixguard-for-mobile',
  id: 'ixguard-for-mobile',
  title: 'iXGuard [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'cocoapods-for-mobile',
  id: 'cocoapods-for-mobile',
  title: 'Cocoapods [mobile]',
  quadrant: 'langs-frameworks-tools',
  description: 'TODO',
});

// *******************************************************************************
// **************** Process & Development Techniques entries *********************
// *******************************************************************************

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'code-review',
  id: 'code-review',
  title: 'Code reviews',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'test-automation',
  id: 'test-automation',
  title: 'Test Automation',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'ddd',
  id: 'ddd',
  title: 'Domain Driven Design',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'static-code-analysis',
  id: 'static-code-analysis',
  title: 'Static Code Analysis',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'agile',
  id: 'agile',
  title: 'Agile',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'threat-models',
  id: 'threat-models',
  title: 'Threat modeling',
  quadrant: 'process-dev-tech',
  description: 'TODO',
});

// *******************************************************************************
// **************** Architectures entries ****************************************
// *******************************************************************************

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'microservices',
  id: 'microservices',
  title: 'Microservices',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'serverless',
  id: 'serverless',
  title: 'Serverless',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'multitenancy',
  id: 'multitenancy',
  title: 'Multitenancy',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'singletenancy',
  id: 'singletenancy',
  title: 'Singletenancy',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'micro-frontends',
  id: 'micro-frontends',
  title: 'micro frontends',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'single-page-app',
  id: 'single-page-app',
  title: 'Single-page App',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'basic-auth',
  id: 'basic-auth',
  title: 'Basic Authentication',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'mvc-for-mobile',
  id: 'mvc-for-mobile',
  title: 'MVC [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'mvp-for-mobile',
  id: 'mvp-for-mobile',
  title: 'MVP [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'mvvm-for-mobile',
  id: 'mvvm-for-mobile',
  title: 'MVVM [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'viper-for-mobile',
  id: 'viper-for-mobile',
  title: 'VIPER [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'grasp-for-mobile',
  id: 'grasp-for-mobile',
  title: 'GRASP [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'experimental',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'clean-swift-for-mobile',
  id: 'clean-swift-for-mobile',
  title: 'Clean Swift [mobile]',
  quadrant: 'architecture',
  description: 'TODO',
});

// *******************************************************************************
// **************** Infrastructure & platforms entries ***************************
// *******************************************************************************

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'aws',
  id: 'aws',
  title: 'AWS',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'circleci',
  id: 'circleci',
  title: 'CircleCI',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'github',
  id: 'github',
  title: 'GitHub',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'jenkins',
  id: 'jenkins',
  title: 'Jenkins',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'k8s',
  id: 'k8s',
  title: 'Kubernetes',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'elk',
  id: 'elk',
  title: 'ELK',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2021-11-21'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'bitbucket',
  id: 'bitbucket',
  title: 'BitBucket',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'bitrise',
  id: 'bitrise',
  title: 'Bitrise [mobile]',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'appcenter',
  id: 'appcenter',
  title: 'AppCenter [mobile]',
  quadrant: 'infra-platforms',
  description: 'TODO',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-12-03'),
      description: 'Initial TechRadar',
    },
  ],
  url: '#',
  key: 'sonarcloud',
  id: 'sonarcloud',
  title: 'SonarCloud',
  quadrant: 'infra-platforms',
  description: 'TODO',
});

export const techRadarResponse: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};

export class OneWelcomeTechRadarApi implements TechRadarApi {
  async load() {
    return techRadarResponse;
  }
}
